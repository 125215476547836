import '~/assets/styles/index.scss';

import React from 'react';
import { GeoLocationProvider } from '~/app/context';
import '@popperjs/core/dist/umd/popper.min';
import 'bootstrap/dist/js/bootstrap.min';

export const wrapRootElement = ({ element }) => {
  return <GeoLocationProvider>{element}</GeoLocationProvider>;
};

setTimeout(() => {
  (window.__ctm_loaded || []).push(function () {
    __ctm.form.capture(
      'app.calltrackingmetrics.com', // the capture host
      'FRT472ABB2C5B9B141A3A4E0316439177295170635A950814719571BB67A2A918D9', // this FormReactor
      document.getElementById('form-rent-a_b'), // ID of the web form you want to attach your FormReactor
      '3237452425', // the tracking number used for associating visitor data and caller ID consumers will see
      {
        country_code: '1',
        name: 'name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
        phone: 'phone', // ID of the lead phone number field
        email: 'email',
        fields: [
          'led_wall_size',
          'drop_of_date',
          'drop_of_time',
          'pick_up_date',
          'pick_up_time',
          'address',
          'city',
          'state',
          'zip_code',
          'comment',
          'range-slider',
          'delivery',
          'setup',
          'operation',
          'pick_yourself',
          'wedding',
          'birthday',
          'conference',
          'corporate',
        ], // list of additional field IDs in the form you want to capture
      }
    );
  });
}, 1500);
